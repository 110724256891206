import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-calendar-config-modal',
  templateUrl: './calendar-config-modal.component.html',
  styleUrls: ['./calendar-config-modal.component.scss']
})
export class CalendarConfigModalComponent {
  @Input() visibleDays: Set<number>;
  @Input() mostrarCabinas: boolean;
  @Input() slotDuration: string;
  @Input() mostrarAvisoSolapamiento: boolean;
  @Input() mostrarAusencias: boolean;
  @Input() mostrarEventosEstudio: boolean;
  @Input() mostrarCitasCanceladas: boolean;
  @Input() mostrarHoraFin: boolean;
  @Input() mostrarPrecio: boolean;
  @Input() mostrarTipoTrabajo: boolean;
  @Output() dayChange = new EventEmitter<{day: number, visible: boolean}>();
  @Output() cabinasChange = new EventEmitter<boolean>();
  @Output() slotDurationChange = new EventEmitter<string>();
  @Output() solapamientoChange = new EventEmitter<boolean>();
  @Output() ausenciasChange = new EventEmitter<boolean>();
  @Output() eventosEstudioChange = new EventEmitter<boolean>();
  @Output() citasCanceladasChange = new EventEmitter<boolean>();
  @Output() horaFinChange = new EventEmitter<boolean>();
  @Output() precioChange = new EventEmitter<boolean>();
  @Output() tipoTrabajoChange = new EventEmitter<boolean>();

  weekDays = [
    { value: 1, label: 'Lunes' },
    { value: 2, label: 'Martes' },
    { value: 3, label: 'Miércoles' },
    { value: 4, label: 'Jueves' },
    { value: 5, label: 'Viernes' },
    { value: 6, label: 'Sábado' },
    { value: 0, label: 'Domingo' }
  ];
  timeIntervals = [
    { value: '00:15:00', label: '15 minutos' },
    { value: '00:30:00', label: '30 minutos' },
    { value: '01:00:00', label: '1 hora' }
  ];

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  isVisible(day: number): boolean {
    return this.visibleDays.has(day);
  }

  toggleDay(day: number, event: any) {
    this.dayChange.emit({day, visible: event.target.checked});
  }

  toggleCabinas(event: any) {
    this.cabinasChange.emit(event.target.checked);
  }

  onSlotDurationChange(event: any) {
    this.slotDurationChange.emit(event.target.value);
  }

  toggleAvisoSolapamiento(event: any) {
    this.solapamientoChange.emit(event.target.checked);
  }

  toggleAusencias(event: any) {
    this.ausenciasChange.emit(event.target.checked);
  }

  toggleEventosEstudio(event: any) {
    this.eventosEstudioChange.emit(event.target.checked);
  }

  toggleCitasCanceladas(event: any) {
    this.citasCanceladasChange.emit(event.target.checked);
  }

  toggleHoraFin(event: any) {
    this.horaFinChange.emit(event.target.checked);
  }

  togglePrecio(event: any) {
    this.precioChange.emit(event.target.checked);
  }

  toggleTipoTrabajo(event: any) {
    this.tipoTrabajoChange.emit(event.target.checked);
  }
}