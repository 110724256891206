<div class="modal-header">
     <h5 class="modal-title font-size-15 text-light">
     <i class="bx bx-cog"></i>
       Configuración del calendario
     </h5>
     <button type="button" class="close-button" aria-label="Close" (click)="activeModal.dismiss()">
          <i class="bx bx-x"></i>
     </button>
</div>
<div class="modal-body">
     <div class="config-section">
          <div class="section-header">
               <i class="bx bx-calendar-check"></i>
               <span>Días visibles <small>(Gana espacio ocultando los días de cierre)</small></span>
          </div>
       <div class="days-container">
         <div class="day-item" *ngFor="let day of weekDays">
           <label class="custom-checkbox">
             <input type="checkbox" 
                    [id]="'day-' + day.value"
                    [checked]="isVisible(day.value)"
                    (change)="toggleDay(day.value, $event)">
             <span class="checkmark"></span>
             <span class="day-label">{{ day.label }}</span>
           </label>
         </div>
       </div>
     </div>
     <div class="config-section mt-4">
          <div class="section-header">
            <i class="bx bx-check-square"></i>
            <span>Elementos visibles</span>
          </div>
          <div class="days-container elements-container"> <!-- Añadida nueva clase -->
               <div class="day-item">
                    <label class="custom-checkbox">
                         <input type="checkbox" 
                              [checked]="mostrarCabinas"
                              (change)="toggleCabinas($event)">
                         <span class="checkmark"></span>
                         <span class="day-label">Nombre cabina</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarAvisoSolapamiento"
                             (change)="toggleAvisoSolapamiento($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Aviso solapamiento</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarAusencias"
                             (change)="toggleAusencias($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Ausencias artistas</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarEventosEstudio"
                             (change)="toggleEventosEstudio($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Eventos</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarCitasCanceladas"
                             (change)="toggleCitasCanceladas($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Citas canceladas</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarHoraFin"
                             (change)="toggleHoraFin($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Hora de fin</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarPrecio"
                             (change)="togglePrecio($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Precio del trabajo</span>
                    </label>
               </div>
               <div class="day-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" 
                             [checked]="mostrarTipoTrabajo"
                             (change)="toggleTipoTrabajo($event)">
                      <span class="checkmark"></span>
                      <span class="day-label">Tipo de trabajo</span>
                    </label>
               </div>
          </div>
     </div>
     <div class="config-section">
          <div class="section-header">
            <i class="bx bx-time"></i>
            <span>Intervalo de tiempo (Vistas semana y día)</span>
          </div>
          <div class="days-container elements-container">
            <div class="day-item" *ngFor="let interval of timeIntervals">
              <label class="custom-checkbox">
                <input type="radio" 
                       name="timeInterval"
                       [value]="interval.value"
                       [checked]="interval.value === slotDuration"
                       (change)="onSlotDurationChange($event)">
                <span class="checkmark"></span>
                <span class="day-label">{{ interval.label }}</span>
              </label>
            </div>
          </div>
     </div>
</div>