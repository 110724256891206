import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import * as global from './global.service';
import { InjectorInstance } from '../app.module';
import { AuthenticationService } from './authentication.service';

@Injectable({providedIn: 'root'})
export class ApicallsService {
  reqHeader: any;

  constructor(public http: HttpClient) {
    var userlocal =  localStorage.getItem('currentUser');
    var userlocaljson = JSON.parse(userlocal);
    this.reqHeader = new HttpHeaders({ 
      'Permiso': 'fe433b94b734e301e201ca71ed64ac85'
   });
  }

  logRedirection(data: any) {
    return this.http.post(global.API_URL + '/api/log-redirection', data, { headers: this.reqHeader });
  }

  // USUARIOS
  getUsuariosEstudioNew(estudio:any) {
    return this.http.get(global.API_URL + '/api/users/estudio_new/'+estudio, { headers: this.reqHeader });
  }
  newUser(usuario: any) {
    return this.http.post( global.API_URL + '/api/users/register/', usuario, { headers: this.reqHeader });
  }
  checkUserMail(email){
    return this.http.get( global.API_URL + '/api/users/check_usermail/' + email, { headers: this.reqHeader });
  }
  editUserWizard(usuario: any, id: string) {
    return this.http.post( global.API_URL + '/api/users/update_wizard/' + id, usuario, { headers: this.reqHeader });
  }
  editUser(usuario: any, id: string) {
    return this.http.post( global.API_URL + '/api/users/update/' + id, usuario, { headers: this.reqHeader });
  }
  forgotPassword(email: any) {
    return this.http.post(global.API_URL + '/api/users/forgotpassword', email, { headers: this.reqHeader });
  }
  updatePassword(usuario: any, id: any){
    return this.http.post( global.API_URL + '/api/users/change_password/' + id, usuario, { headers: this.reqHeader });
  }
  getRoleInfo(id:any){
    return this.http.get( global.API_URL + '/api/users/infousuario/'+id, { headers: this.reqHeader });
  }
  desbloquear(password: any, id: any){
    return this.http.post( global.API_URL + '/api/users/desbloquear/' + id, password, { headers: this.reqHeader });
  }
  getUser(usuario: any) {
    return this.http.get( global.API_URL + '/api/users/view/'+usuario, { headers: this.reqHeader });
  }
  wizardSkipManagers(id: string) {
    return this.http.get( global.API_URL + '/api/users/skip_managers/' + id, { headers: this.reqHeader });
  }
  wizardSkipArtistas(id: string) {
    return this.http.get( global.API_URL + '/api/users/skip_artistas/' + id, { headers: this.reqHeader });
  }
  actualizarPreferenciaNuevaPestana(nueva_pestana: any, id: any){
    return this.http.post( global.API_URL + '/api/users/actualizarPreferenciaNuevaPestana/' + id, { nueva_pestana }, { headers: this.reqHeader });
  }

  

  // CABINAS
  getCabinasEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/cabinas/estudio/'+estudio, { headers: this.reqHeader });
  }
  newCabina(cabina: any) {
    return this.http.post( global.API_URL + '/api/cabinas/create/', cabina, { headers: this.reqHeader });
  }
  getCabina(id: string) {
    return this.http.get(global.API_URL + '/api/cabinas/view/' + id, { headers: this.reqHeader });
  }
  editCabina(cabina: any, id: string) {
    return this.http.post( global.API_URL + '/api/cabinas/update/' + id, cabina, { headers: this.reqHeader });
  }
  deleteCabina(id: string) {
    return this.http.delete(global.API_URL + '/api/cabinas/delete/' + id, { headers: this.reqHeader });
  }
  
  // GASTOS
  getGastosEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/gastos/estudio/'+estudio, { headers: this.reqHeader });
  }
  newGasto(gasto: any) {
    return this.http.post( global.API_URL + '/api/gastos/create/', gasto, { headers: this.reqHeader });
  }
  getGasto(id: string) {
    return this.http.get(global.API_URL + '/api/gastos/view/' + id, { headers: this.reqHeader });
  }
  editGasto(gasto: any, id: string) {
    return this.http.post( global.API_URL + '/api/gastos/update/' + id, gasto, { headers: this.reqHeader });
  }
  deleteGasto(id: string) {
    return this.http.delete(global.API_URL + '/api/gastos/delete/' + id, { headers: this.reqHeader });
  }
  
  // INVENTARIO
  getArticulosEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/inventario/estudio/'+estudio, { headers: this.reqHeader });
  }
  updateUnidades(id: string, unidades: number) {
    return this.http.post(global.API_URL + '/api/inventario/update-unidades/' + id, { unidades }, { headers: this.reqHeader });
  }
  newArticulo(articulo: any) {
    return this.http.post( global.API_URL + '/api/inventario/create/', articulo, { headers: this.reqHeader });
  }
  getArticulo(id: string) {
    return this.http.get(global.API_URL + '/api/inventario/view/' + id, { headers: this.reqHeader });
  }
  getCategorias(estudioId: number) {
    return this.http.get(`${global.API_URL}/api/inventario/categorias/${estudioId}`, { headers: this.reqHeader });
  }


  editArticulo(articulo: any, id: string) {
    return this.http.post( global.API_URL + '/api/inventario/update/' + id, articulo, { headers: this.reqHeader });
  }
  deleteArticulo(id: string) {
    return this.http.delete(global.API_URL + '/api/inventario/delete/' + id, { headers: this.reqHeader });
  }

  // UBICACIONES
  getUbicacionesEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/ubicaciones/estudio/'+estudio, { headers: this.reqHeader });
  }
  newUbicacion(cabina: any) {
    return this.http.post( global.API_URL + '/api/ubicaciones/create/', cabina, { headers: this.reqHeader });
  }
  getUbicacion(id: string) {
    return this.http.get(global.API_URL + '/api/ubicaciones/view/' + id, { headers: this.reqHeader });
  }
  editUbicacion(cabina: any, id: string) {
    return this.http.post( global.API_URL + '/api/ubicaciones/update/' + id, cabina, { headers: this.reqHeader });
  }
  deleteUbicacion(id: string) {
    return this.http.delete(global.API_URL + '/api/ubicaciones/delete/' + id, { headers: this.reqHeader });
  }

  // ESTUDIOS
  getEstudios() {
    return this.http.get(global.API_URL + '/api/estudios', { headers: this.reqHeader });
  }
  getCatProductosEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/catproductos/estudio/'+estudio, { headers: this.reqHeader });
  }
  getProductosEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/productos/estudio/'+estudio, { headers: this.reqHeader });
  }
  getProductosEstudioAll(estudio:any) {
    return this.http.get(global.API_URL + '/api/productos/estudio_all/'+estudio, { headers: this.reqHeader });
  }
  getProductosEstudioFilter(estudio:any, info: any) {
    return this.http.post(global.API_URL + '/api/productos/index_filtered/'+estudio,info, { headers: this.reqHeader });
  }
  getTicketsEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/tickets/estudio/'+estudio, { headers: this.reqHeader });
  }
  getTicketsEstudioFilter(estudio:any, info: any) {
    return this.http.post(global.API_URL + '/api/tickets/index_filtered/'+estudio,info, { headers: this.reqHeader });
  }
  getNotificaciones(estudio) {
    return this.http.get(global.API_URL + '/api/estudios/notificaciones/'+estudio, { headers: this.reqHeader });
  }
  getComunicaciones(estudio) {
    return this.http.get(global.API_URL + '/api/estudios/comunicaciones/'+estudio, { headers: this.reqHeader });
  }
  newEstudio(estudio: any) {
    return this.http.post( global.API_URL + '/api/estudios/create/', estudio, { headers: this.reqHeader });
  }
  getCalendariosEstudio(id: string) {
    return this.http.get<any>(global.API_URL + '/api/estudios/get_calendarios/' + id, { headers: this.reqHeader });
  }
  getEventosCalendarioEstudio(idEstudio: string, calendarId: string) {
    return this.http.get<any>(
      global.API_URL + '/api/estudios/get_eventos_calendario/' + idEstudio + '/' + calendarId,
      { headers: this.reqHeader }
    );
  }  
  newCatProducto(categoria: any) {
    return this.http.post( global.API_URL + '/api/catproductos/create/', categoria, { headers: this.reqHeader });
  }
  newProducto(producto: any) {
    return this.http.post( global.API_URL + '/api/productos/create/', producto, { headers: this.reqHeader });
  }
  newTicket(ticket: any) {
    return this.http.post( global.API_URL + '/api/tickets/create/', ticket, { headers: this.reqHeader });
  }
  crearComunicacion(comunicacion:any, estudio:any){
    return this.http.post( global.API_URL + '/api/estudios/comunicaciones/new/' + estudio, comunicacion, { headers: this.reqHeader });
  }
  actualizarComunicacion(comunicacion:any, id:any){
    return this.http.post( global.API_URL + '/api/estudios/comunicaciones/edit/' + id, comunicacion, { headers: this.reqHeader });
  }
  activarComunicacion(comunicacion) {
    return this.http.get(global.API_URL + '/api/estudios/comunicaciones/enable/'+comunicacion, { headers: this.reqHeader });
  }
  desactivarComunicacion(comunicacion) {
    return this.http.get(global.API_URL + '/api/estudios/comunicaciones/disable/'+comunicacion, { headers: this.reqHeader });
  }
  newEstudioWizard(estudio: any, user:any) {
    return this.http.post( global.API_URL + '/api/estudios/create_wizard/' + user, estudio, { headers: this.reqHeader });
  }
  getEstudio(id: string) {
    return this.http.get(global.API_URL + '/api/estudios/view/' + id, { headers: this.reqHeader });
  }
  getCatProductos(id: string) {
    return this.http.get(global.API_URL + '/api/catproductos/view/' + id, { headers: this.reqHeader });
  }
  getProductos(id: string) {
    return this.http.get(global.API_URL + '/api/productos/view/' + id, { headers: this.reqHeader });
  }
  getTickets(id: string) {
    return this.http.get(global.API_URL + '/api/tickets/view/' + id, { headers: this.reqHeader });
  }
  descargarTicket(id: string) {
    return this.http.get(global.API_URL + '/api/tickets/descargar_ticket/' + id, { headers: this.reqHeader });
  }
  enviarTicket(id: string) {
    return this.http.get(global.API_URL + '/api/tickets/enviar_ticket/' + id, { headers: this.reqHeader });
  }
  editEstudio(estudio: any, id: string) {
    return this.http.post( global.API_URL + '/api/estudios/update/' + id, estudio, { headers: this.reqHeader });
  }
  editCatProducto(categoria: any, id: string) {
    return this.http.post( global.API_URL + '/api/catproductos/update/' + id, categoria, { headers: this.reqHeader });
  }
  editProducto(producto: any, id: string) {
    return this.http.post( global.API_URL + '/api/productos/update/' + id, producto, { headers: this.reqHeader });
  }
  editTicket(ticket: any, id: string) {
    return this.http.post( global.API_URL + '/api/tickets/update/' + id, ticket, { headers: this.reqHeader });
  }
  editEstudioConsentimiento(consentimiento: any, id: string) {
    return this.http.post( global.API_URL + '/api/estudios/update_consentimiento/' + id, consentimiento, { headers: this.reqHeader });
  }
  editEstudioConsentimientoPiercing(consentimiento: any, id: string) {
    return this.http.post( global.API_URL + '/api/estudios/update_consentimiento_piercing/' + id, consentimiento, { headers: this.reqHeader });
  }
  editEstudioConsentimientoLaser(consentimiento: any, id: string) {
    return this.http.post( global.API_URL + '/api/estudios/update_consentimiento_laser/' + id, consentimiento, { headers: this.reqHeader });
  }
  editEstudioConsentimientoMicroblading(consentimiento: any, id: string) {
    return this.http.post( global.API_URL + '/api/estudios/update_consentimiento_microblading/' + id, consentimiento, { headers: this.reqHeader });
  }
  editEstudioConsentimientoEstetica(consentimiento: any, id: string) {
    return this.http.post( global.API_URL + '/api/estudios/update_consentimiento_estetica/' + id, consentimiento, { headers: this.reqHeader });
  }
  deleteEstudio(id: string) {
    return this.http.delete(global.API_URL + '/api/estudios/delete/' + id, { headers: this.reqHeader });
  }
  updateAvisoCabinas(idEstudio: number, avisoStatus: boolean) {
    return this.http.put(`${global.API_URL}/api/estudios/${idEstudio}/aviso-cabinas`, {aviso_cabinas: avisoStatus}, { headers: this.reqHeader });
  }
  getTintasEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/tintas/estudio/'+estudio, { headers: this.reqHeader });
  }
  newTinta(tinta: any) {
    return this.http.post( global.API_URL + '/api/tintas/create/', tinta, { headers: this.reqHeader });
  }
  getTinta(id: string) {
    return this.http.get(global.API_URL + '/api/tintas/view/' + id, { headers: this.reqHeader });
  }
  editTinta(tinta: any, id: string) {
    return this.http.post( global.API_URL + '/api/tintas/update/' + id, tinta, { headers: this.reqHeader });
  }
  deleteTinta(id: string) {
    return this.http.delete(global.API_URL + '/api/tintas/delete/' + id, { headers: this.reqHeader });
  }
  /*Agujas*/
  getAgujasEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/agujas/estudio/'+estudio, { headers: this.reqHeader });
  }
  newAguja(aguja: any) {
    return this.http.post( global.API_URL + '/api/agujas/create/', aguja, { headers: this.reqHeader });
  }
  getAguja(id: string) {
    return this.http.get(global.API_URL + '/api/agujas/view/' + id, { headers: this.reqHeader });
  }
  editAguja(aguja: any, id: string) {
    return this.http.post( global.API_URL + '/api/agujas/update/' + id, aguja, { headers: this.reqHeader });
  }
  deleteAguja(id: string) {
    return this.http.delete(global.API_URL + '/api/agujas/delete/' + id, { headers: this.reqHeader });
  }
  /*Inspecciones*/
  getInspeccionesEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/inspecciones/estudio/'+estudio, { headers: this.reqHeader });
  }
  newInspeccion(inspeccion: any) {
    return this.http.post( global.API_URL + '/api/inspecciones/create/', inspeccion, { headers: this.reqHeader });
  }
  getInspeccion(id: string) {
    return this.http.get(global.API_URL + '/api/inspecciones/view/' + id, { headers: this.reqHeader });
  }
  editInspeccion(inspeccion: any, id: string) {
    return this.http.post( global.API_URL + '/api/inspecciones/update/' + id, inspeccion, { headers: this.reqHeader });
  }
  deleteInspeccion(id: string) {
    return this.http.delete(global.API_URL + '/api/inspecciones/delete/' + id, { headers: this.reqHeader });
  }
  getInspecciones() {
    return this.http.get(global.API_URL + '/api/inspecciones', { headers: this.reqHeader });
  }  
  getCoordsByCp(cp: string) {
    return this.http.get(global.API_URL + `/api/inspecciones/codigos_postales/${cp}`, { headers: this.reqHeader });
  }
  getInspeccionesAviso(estudioId: number) {
    return this.http.get(global.API_URL + `/api/inspecciones/avisos/${estudioId}`, { headers: this.reqHeader });
  }
  marcarAvisosComoLeidos(estudioId: number) {
    const body = { estudio_id: estudioId };
    return this.http.post(`${global.API_URL}/api/inspecciones/marcar_leidos`, body, { headers: this.reqHeader });
  }  
  
  

  deleteCatProducto(id: string) {
    return this.http.delete(global.API_URL + '/api/catproductos/delete/' + id, { headers: this.reqHeader });
  }
  deleteProducto(id: string) {
    return this.http.delete(global.API_URL + '/api/productos/delete/' + id, { headers: this.reqHeader });
  }
  
  
  updateTag(estudioId: number, oldTag: string, newTag: string) {
    const headers = this.reqHeader;
    return this.http.post(
        `${global.API_URL}/api/productos/updateTag/${estudioId}`,
        { oldTag, newTag },
        { headers }
    );
  }
  deleteTag(estudioId: number, tag: string) {
    const headers = this.reqHeader; // Incluye los headers necesarios, como Authorization
    return this.http.delete(
        `${global.API_URL}/api/productos/deleteTag/${estudioId}`,
        { headers, body: { tag } }
    );
  }





  deleteTicket(id: string) {
    return this.http.delete(global.API_URL + '/api/tickets/delete/' + id, { headers: this.reqHeader });
  }
  suscripcionStripe(suscripcion, token, customer) {
    const obj: any = {};
    obj.stripeToken = token;
    obj.suscripcion = suscripcion;
    obj.customer = customer;

    return this.http.post(global.API_URL + '/api/estudios/suscripcion_stripe/',obj, { headers: this.reqHeader });
  }

confirmarPagoStripe(customer) {
    return this.http.get(global.API_URL + '/api/estudios/suscripcion_stripe_confirm/'+customer, { headers: this.reqHeader });
  }

  deSuscripcionStripe(customer, suscripcion, motivo) {
    const obj: any = {};
    obj.suscripcion = suscripcion;
    obj.customer = customer;
    obj.motivo = motivo;

    return this.http.post(global.API_URL + '/api/estudios/desuscripcion_stripe/',obj, { headers: this.reqHeader });
  }

  forzarBajaStripe(customer, suscripcion, motivo) {
    const obj: any = {};
    obj.suscripcion = suscripcion;
    obj.customer = customer;
    obj.motivo = motivo;

    return this.http.post(global.API_URL + '/api/estudios/forzar_baja_stripe/',obj, { headers: this.reqHeader });
  }

  getPagos(estudio){
    return this.http.get(global.API_URL + '/api/estudios/pagos_stripe/'+estudio, { headers: this.reqHeader });
  }
  
  recibirFacturas(estudio, condicion){
    return this.http.get(global.API_URL + '/api/estudios/recibir_facturas/'+estudio+'/'+condicion, { headers: this.reqHeader });
  }
  checkSubscription(suscripcion){
    return this.http.get(global.API_URL + '/api/estudios/check_subscription/'+suscripcion, { headers: this.reqHeader });
  }
  activarSuscripcionFake(estudio){
    return this.http.get(global.API_URL + '/api/estudios/fake_activar_suscripcion/'+estudio, { headers: this.reqHeader });
  }

  cancelarSuscripcionFake(estudio){
    return this.http.get(global.API_URL + '/api/estudios/fake_cancelar_suscripcion/'+estudio, { headers: this.reqHeader });
  }

  confirmarBaja(estudio){
    return this.http.get(global.API_URL + '/api/estudios/confirmar_baja/'+estudio, { headers: this.reqHeader });
  }

  getTarjeta(estudio){
    return this.http.get(global.API_URL + '/api/estudios/tarjeta_stripe/'+estudio, { headers: this.reqHeader });
  }
  
  finishTrial(estudio){
    return this.http.get(global.API_URL + '/api/estudios/finish_trial/'+estudio, { headers: this.reqHeader });
  }
  
  getEstudioHorario(estudio_id: number){
    return this.http.get(global.API_URL + '/api/estudios/estudios_dias/' + estudio_id, { headers: this.reqHeader });
  }
  getDatosMensuales(estudioId: number) {
    return this.http.get(global.API_URL + '/api/estudios/' + estudioId + '/datosMensuales', { headers: this.reqHeader });
  }
  getFestivos(estudioId: number) {
    return this.http.get(global.API_URL + '/api/estudios/' + estudioId + '/festivos', { headers: this.reqHeader });
  }
  guardarFestivo(data): Observable<any> {
    return this.http.post(global.API_URL + '/api/estudios/addFestivo', data, { headers: this.reqHeader });
  }
  eliminarFestivo(id: number) {
  return this.http.delete(global.API_URL + '/api/estudios/festivos/delete/' + id, { headers: this.reqHeader });
  }
  activarPerfilPublico(idEstudio: number) {
    return this.http.get(global.API_URL + '/api/estudios/activar_perfil_publico/' + idEstudio, { headers: this.reqHeader });
  }
  desactivarPerfilPublico(idEstudio: number) {
    return this.http.get(global.API_URL + '/api/estudios/desactivar_perfil_publico/' + idEstudio, { headers: this.reqHeader });
  }
  //Nueva vista ventas
  getTicketsPorMes(estudioId: number, year: number, month: number, tipo: string) {
    return this.http.get(`${global.API_URL}/api/tickets/estudio/${estudioId}/${year}/${month}/${tipo}`, { headers: this.reqHeader });
  }
  descargarTodosLosTickets(estudioId: number, year: number, month: number, tipo: string): Observable<Blob> {
    return this.http.get(`${global.API_URL}/api/tickets/${estudioId}/${year}/${month}/${tipo}`, { 
      headers: this.reqHeader,
      responseType: 'blob'  // Asegúrate de que la respuesta se maneje como un Blob
    });
  }
  getEventosArtistasVinculados(estudioId: number, anio: number, mes: number) {
    return this.http.get<any>(
      global.API_URL + `/api/estudios/${estudioId}/eventos_artistas_vinculados`,
      {
        headers: this.reqHeader,
        params: {
          anio: anio.toString(),
          mes: mes.toString()
        }
      }
    );
  }
  
  mejorarTexto(datos: any): Observable<any> {
    return this.http.post(`${global.API_URL}/api/estudios/mejorar-texto`, datos, {headers: this.reqHeader});
  }
  



  // UBICACIONES
  getAllPaises(){
    return this.http.get(global.API_URL + '/api/paises', { headers: this.reqHeader });
  }
  getAllComunidades() {
    return this.http.get(global.API_URL + '/api/comunidades', { headers: this.reqHeader });
  }
  getProvinciaByPais(id: string) {
    return this.http.get(global.API_URL + '/api/provinces/pais/' + id, { headers: this.reqHeader });
  }
  getProvinciaByComunidad(id: string) {
    return this.http.get(global.API_URL + '/api/provinces/comunidad/' + id, { headers: this.reqHeader });
  }
  getCiudadesByProvincia(id: string){
    return this.http.get(global.API_URL + '/api/cities/' + id, { headers: this.reqHeader });
  }
  getCiudadById(id: string) {
    return this.http.get(global.API_URL + '/api/ciudad/' + id, { headers: this.reqHeader });
  }
  getProvinciaById(id: string) {
    return this.http.get(global.API_URL + '/api/provincia/' + id, { headers: this.reqHeader });
  }
    

  //DIAS

  getAllDays(){
    return this.http.get(global.API_URL + '/api/dias', { headers: this.reqHeader });
  }

  //REDES

  getAllRedesSociales(){
    return this.http.get(global.API_URL + '/api/redessociales', { headers: this.reqHeader });
  }

  // MANAGERS
  getManagers() {
    return this.http.get(global.API_URL + '/api/managers', { headers: this.reqHeader });
  }
  getManagersEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/managers/estudio/'+estudio, { headers: this.reqHeader });
  }
  contarManagers(estudioId: any) {
    return this.http.get(global.API_URL + '/api/managers/estudio/' + estudioId + '/contarManagers', { headers: this.reqHeader });
  }
  getPermisosManager(id: string) {
    return this.http.get(global.API_URL + '/api/managers/consultarPermisos/' + id, { headers: this.reqHeader });
  }  
  newManager(manager: any) {
    return this.http.post( global.API_URL + '/api/managers/create/', manager, { headers: this.reqHeader });
  }
  newManagerWizard(manager: any, user:any) {
    return this.http.post( global.API_URL + '/api/managers/create_wizard/'+ user, manager, { headers: this.reqHeader });
  }
  getManager(id: string) {
    return this.http.get(global.API_URL + '/api/managers/view/' + id, { headers: this.reqHeader });
  }
  getManagerNew(id: string) {
    return this.http.get(global.API_URL + '/api/managers/view_new/' + id, { headers: this.reqHeader });
  }
  sendInvitacionManager(id: string) {
    return this.http.get(global.API_URL + '/api/managers/reenvio_mail/' + id, { headers: this.reqHeader });
  }
  editManager(manager: any, id: string) {
    return this.http.post( global.API_URL + '/api/managers/update/' + id, manager, { headers: this.reqHeader });
  }
  deleteManager(id: string) {
    return this.http.delete(global.API_URL + '/api/managers/delete/' + id, { headers: this.reqHeader });
  }

  // TARIFAS
  getTarifas() {
    return this.http.get(global.API_URL + '/api/tarifas', { headers: this.reqHeader });
  }
  getTarifasEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/tarifas/estudio/'+estudio, { headers: this.reqHeader });
  }
  
  newTarifa(tarifa: any) {
    return this.http.post( global.API_URL + '/api/tarifas/create/', tarifa, { headers: this.reqHeader });
  }
  getTarifa(id: string) {
    return this.http.get(global.API_URL + '/api/tarifas/view/' + id, { headers: this.reqHeader });
  }
  editTarifa(tarifa: any, id: string) {
    return this.http.post( global.API_URL + '/api/tarifas/update/' + id, tarifa, { headers: this.reqHeader });
  }
  deleteTarifa(id: string) {
    return this.http.delete(global.API_URL + '/api/tarifas/delete/' + id, { headers: this.reqHeader });
  }

   // ARTISTAS
   getArtistas() {
    return this.http.get(global.API_URL + '/api/artistas', { headers: this.reqHeader });
  }
  getArtistasEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/artistas/estudio/'+estudio, { headers: this.reqHeader });
  }
  getArtistasNew(estudio:string) {
    return this.http.get(global.API_URL + '/api/artistas/getArtistasNew/'+estudio, { headers: this.reqHeader });
  }
  contarArtistas(estudioId: any) {
    return this.http.get(global.API_URL + '/api/artistas/estudio/' + estudioId + '/contarArtistas', { headers: this.reqHeader });
  }
  newArtista(artista: any) {
    return this.http.post( global.API_URL + '/api/artistas/create/', artista, { headers: this.reqHeader });
  }
  newDocumentArtista(documento:any, artista:any){
    return this.http.post( global.API_URL + '/api/artistas/create_doc/' + artista, documento, { headers: this.reqHeader });
  }
  sendInvitacionArtista(id: string) {
    return this.http.get(global.API_URL + '/api/artistas/reenvio_mail/' + id, { headers: this.reqHeader });
  }
  newArtistaWizard(artista: any, user:any) {
    return this.http.post( global.API_URL + '/api/artistas/create_wizard/'+ user, artista, { headers: this.reqHeader });
  }
  getArtista(id: string) {
    return this.http.get(global.API_URL + '/api/artistas/view/' + id, { headers: this.reqHeader });
  }
  getArtistaNew(id: string) {
    return this.http.get(global.API_URL + '/api/artistas/view_new/' + id, { headers: this.reqHeader });
  }
  editArtista(artista: any, id: string) {
    return this.http.post( global.API_URL + '/api/artistas/update/' + id, artista, { headers: this.reqHeader });
  }
  deleteArtista(id: string) {
    return this.http.delete(global.API_URL + '/api/artistas/delete/' + id, { headers: this.reqHeader });
  }
  deleteDocumentArtista(id: string) {
    return this.http.delete(global.API_URL + '/api/artistas/delete_doc/' + id, { headers: this.reqHeader });
  }
  checkColorArtista(color:any, id:any){
    return this.http.post(global.API_URL + '/api/artistas/check_color/'+id, color, { headers: this.reqHeader });
  }
  getAusenciasArtistas(mes: any) {
    return this.http.get(global.API_URL + '/api/artistas/ausencias/mes/' + mes, { headers: this.reqHeader });
  }
  obtenerAusenciasArtistasNEW(estudioId: string) {
    return this.http.get(global.API_URL + '/api/artistas/obtenerAusenciasArtistasNEW/' + estudioId, { headers: this.reqHeader });
  }
  getAusenciasArtistasInterval(fecha_inicial: any, fecha_final: any) {
    return this.http.get(global.API_URL + '/api/artistas/ausencias/fecha1/' + fecha_inicial + '/fecha2/' + fecha_final, { headers: this.reqHeader });
  }
  getAusenciasArtistasIntervalEstudio(fecha_inicial: any, fecha_final: any, estudio_id: any) {
    return this.http.get(global.API_URL + '/api/artistas/estudio/'+ estudio_id +'/ausencias/fecha1/' + fecha_inicial + '/fecha2/' + fecha_final, { headers: this.reqHeader });
  }
  eliminarAusencia(ausenciaId: number) {
    return this.http.delete(global.API_URL + '/api/artistas/borrar_ausencia/' + ausenciaId, { headers: this.reqHeader });
  }
  updateArtistaVisibilidad(artistaId: number, visible: number) {
    const data = { visible_calendar: visible };
    return this.http.post(global.API_URL + '/api/artistas/updateVisibilidad/' + artistaId, data, { headers: this.reqHeader });
  }
  vincularCalendar(usuario:any, codigo:any){
    const obj: any = {};
    obj.usuario = usuario;
    obj.codigo = codigo;
 
    return this.http.post(global.API_URL + '/api/artistas/vincular_calendar/',obj, { headers: this.reqHeader });
  }
  vincularCalendarEstudio(estudio:any, codigo:any){
    const obj: any = {};
    obj.estudio = estudio;
    obj.codigo = codigo;
 
    return this.http.post(global.API_URL + '/api/estudios/vincular_calendar/',obj, { headers: this.reqHeader });
  }
  desvincularCalendarArtista(artistaId: number) {
    return this.http.post<any>(
      global.API_URL + '/api/artistas/desvincular_calendario', { artista_id: artistaId }, { headers: this.reqHeader });
  }  
  getVinculado(id: string) {
    return this.http.get(global.API_URL + '/api/artistas/get_vinculado/' + id, { headers: this.reqHeader });
  }
  getVinculadoEstudio(id: string) {
    return this.http.get(global.API_URL + '/api/estudios/get_vinculado/' + id, { headers: this.reqHeader });
  }  
  crearAusenciaArtista(data: any): Observable<any> {
    return this.http.post(global.API_URL + '/api/artistas/crear_ausencia/',data, { headers: this.reqHeader });
  }
  getPermisosArtista(user_id: string) {
    return this.http.get<any>(global.API_URL + '/api/artistas/getPermisosArtista/' + user_id, { headers: this.reqHeader });
  }

  asignarCalendarioArtista(idArtista: number, idCalendario: string) {
    const obj = {
      artista: idArtista,
      calendario: idCalendario
    };
    return this.http.post<any>(
      global.API_URL + '/api/artistas/asignar_calendario',
      obj,
      { headers: this.reqHeader }
    );
  }  


  

  
  // CLIENTES
  getClientes() {
    return this.http.get(global.API_URL + '/api/clientes', { headers: this.reqHeader });
  }
  getClientesEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/clientes/estudio/'+estudio, { headers: this.reqHeader });
  }
  getClientesEstudioNew(estudio:any) {
    return this.http.get(global.API_URL + '/api/clientes/estudio_new/'+estudio, { headers: this.reqHeader });
  }
  contarClientesEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/clientes/contarClientesEstudio/'+estudio, { headers: this.reqHeader });
  }
  getClientesEstudioNewDocs(estudio:any) {
    return this.http.get(global.API_URL + '/api/clientes/estudio_new_docs/'+estudio, { headers: this.reqHeader });
  }
  getClientesAnfitrion(anfitrion:any) {
    return this.http.get(global.API_URL + '/api/clientes/anfitrion/'+anfitrion, { headers: this.reqHeader });
  }
  newCliente(cliente: any) {
    return this.http.post( global.API_URL + '/api/clientes/create/', cliente, { headers: this.reqHeader });
  }
  newDocumentCliente(documento:any, cliente:any){
    return this.http.post( global.API_URL + '/api/clientes/create_doc/' + cliente, documento, { headers: this.reqHeader });
  }
  newMensajeCliente(mensaje:any, cliente:any){
    return this.http.post( global.API_URL + '/api/clientes/create_mensaje/' + cliente, mensaje, { headers: this.reqHeader });
  }
  getCliente(id: string) {
    return this.http.get(global.API_URL + '/api/clientes/view/' + id, { headers: this.reqHeader });
  }
  getClienteNew(id: string) {
    return this.http.get(global.API_URL + '/api/clientes/view_new/' + id, { headers: this.reqHeader });
  }
  getDocs(id: string) {
    return this.http.get(global.API_URL + '/api/clientes/get_docs/' + id, { headers: this.reqHeader });
  }
  editCliente(cliente: any, id: string) {
    return this.http.post( global.API_URL + '/api/clientes/update/' + id, cliente, { headers: this.reqHeader });
  }
  deleteCliente(id: string) {
    return this.http.delete(global.API_URL + '/api/clientes/delete/' + id, { headers: this.reqHeader });
  }
  deleteDocumentCliente(id: string) {
    return this.http.delete(global.API_URL + '/api/clientes/delete_doc/' + id, { headers: this.reqHeader });
  }
  assignTutor(cliente, tutor){
    return this.http.get(global.API_URL + '/api/clientes/tutor/'+cliente+'/'+tutor, { headers: this.reqHeader });
  }
  asignarTutor(clienteId: number, tutorId: number) {
    const body = { tutor_id: tutorId };
    return this.http.post(global.API_URL + '/api/clientes/asignartutor/' + clienteId, body, { headers: this.reqHeader });
  }  
  getClientesPorNombre(idEstudio: string, query: string): Observable<any> {
    return this.http.get<any>(`${global.API_URL}/api/clientes/estudio/${idEstudio}/buscar/${query}`, { headers: this.reqHeader });
  }
  removeTutorLegal(clienteId: string) {
    const data = { tutor_id: null };
    return this.http.post(global.API_URL + '/api/clientes/update-tutor/' + clienteId, data, { headers: this.reqHeader });
  }
  getArtistasClientesFlexibles(estudio_id: number) {
    return this.http.get(global.API_URL + '/api/clientes/flexibles/artistas/'+estudio_id, { headers: this.reqHeader });
  }
  clientes_solicitantes(estudioId: any) {
    return this.http.get(global.API_URL + '/api/clientes/clientes_solicitantes/' + estudioId, { headers: this.reqHeader });
  }
  solicitudVista(clienteId: string) {
    return this.http.post(global.API_URL + '/api/clientes/solicitudVista/'+clienteId, {}, { headers: this.reqHeader });
  }

  actualizarSolicitudCitaCliente(clienteId: number, solicitudCitaCliente: number) {
    return this.http.put(global.API_URL + '/api/clientes/' + clienteId + '/actualizar-solicitud', { solicitud_cita_cliente: solicitudCitaCliente }, { headers: this.reqHeader });
  }
  getClientesDuplicados(estudioId: any) {
    return this.http.get(global.API_URL + '/api/clientes/duplicados/' + estudioId, { headers: this.reqHeader });
  }
  consolidarCliente(clienteAEliminarId: number, clienteAMantenerId: number) {
    return this.http.post(global.API_URL + '/api/clientes/consolidar', { clienteAEliminarId, clienteAMantenerId}, { headers: this.reqHeader });
  }
  obtenerClientesCP(estudioId: number): Observable<any> {
    return this.http.get(`${global.API_URL}/api/clientes/cp/${estudioId}`, { headers: this.reqHeader });
  }
  obtenerLocalidadPorCP(cp: string): Observable<any> {
    const url = `${global.API_URL}/api/clientes/localidad/${cp}`;
    return this.http.get(url, { headers: this.reqHeader }); 
  }
  obtenerCPCoordenadas(estudioId: number): Observable<any> {
    return this.http.get(`${global.API_URL}/api/clientes/cp-coordenadas/${estudioId}`, { headers: this.reqHeader });
  }
  buscarClientesCoincidentes(payload: any) {
    return this.http.post(global.API_URL + '/api/clientes/buscarCoincidencias', payload, { headers: this.reqHeader });
  }
  /*Bink duplicar cliente en otro estudio*/
  duplicarClienteEnOtroEstudio(clienteId: number, estudioActualId: number) {
    return this.http.post(`${global.API_URL}/api/clientes/duplicar/${clienteId}/${estudioActualId}`, {}, { headers: this.reqHeader });
  }






  // TRABAJOS
  getTrabajos() {
    return this.http.get(global.API_URL + '/api/trabajos', { headers: this.reqHeader });
  }
  getTrabajosEstudio(estudio:any) {
    //return this.http.get(global.API_URL + '/api/trabajos/estudio/'+estudio, { headers: this.reqHeader });
    return this.http.get(global.API_URL + '/api/trabajos/estudio/'+estudio, { headers: this.reqHeader });
  }
  getCitasMultiples(estudio:any) {
    return this.http.get(global.API_URL + '/api/trabajos/traer_citas_multiples/'+estudio, { headers: this.reqHeader });
  }
  getTrabajosEstudioNew(estudio:any, all:any) {
    //return this.http.get(global.API_URL + '/api/trabajos/estudio/'+estudio, { headers: this.reqHeader });
    return this.http.get(global.API_URL + '/api/trabajos/estudio_new/'+estudio+'/'+all, { headers: this.reqHeader });
  }
  getTrabajosEstudioLiquidacion(estudio:any, info: any) {
    return this.http.post(global.API_URL + '/api/trabajos/estudio_liquidacion/'+estudio,info, { headers: this.reqHeader });
  }
  getTrabajosFiltrados(info: any) {
    return this.http.post(global.API_URL + '/api/trabajos/estudio_filtrado',info, { headers: this.reqHeader });
  }
  getNotas() {
    return this.http.get(global.API_URL + '/api/trabajos/notas/', { headers: this.reqHeader });
  }
  getNotasEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/trabajos/notas_estudio/'+estudio, { headers: this.reqHeader });
  }
  getTrabajosTipo(tipo, fase) {
    return this.http.get(global.API_URL + '/api/trabajos/tipo/'+tipo+'/'+fase, { headers: this.reqHeader });
  }
  getTrabajosTipoEstudio(tipo, fase, estudio) {
    return this.http.get(global.API_URL + '/api/trabajos/tipo_estudio/'+tipo+'/'+fase+'/'+estudio, { headers: this.reqHeader });
  }
  getTrabajosTipoEstudioNew(tipo, fase, estudio, all) {
    return this.http.get(global.API_URL + '/api/trabajos/tipo_estudio_new/'+tipo+'/'+fase+'/'+estudio+'/'+all, { headers: this.reqHeader });
  }
  getPreProyectos(){
    return this.http.get(global.API_URL + '/api/trabajos/preproyectos', { headers: this.reqHeader });
  }
  getPreProyectosCliente(cliente){
    return this.http.get(global.API_URL + '/api/trabajos/preproyectos/'+cliente, { headers: this.reqHeader });
  }
  newTrabajo(trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/create/', trabajo, { headers: this.reqHeader });
  }
  newTrabajoExpress(trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/create_express/', trabajo, { headers: this.reqHeader });
  }
  newTrabajoExpressCalendar(trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/create_express_calendar/', trabajo, { headers: this.reqHeader });
  }
  updateTrabajoExpressCalendar(trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/update_express_calendar/', trabajo, { headers: this.reqHeader });
  }
  getTrabajo(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/view/' + id, { headers: this.reqHeader });
  }
  getTrabajoFirma(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/firma/' + id, { headers: this.reqHeader });
  }
  getTrabajoConsInfo(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/cons_info/' + id, { headers: this.reqHeader });
  }
  getBorrador(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/borrador/' + id, { headers: this.reqHeader });
  }
  cancelarTrabajo(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/cancelar/' + id, { headers: this.reqHeader });
  }
  reactivarTrabajo(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/reactivar/' + id, { headers: this.reqHeader });
  }
  reenviar_datos_cita(id: string) {
    return this.http.get(global.API_URL + '/api/trabajos/reenviar_datos_cita/' + id, { headers: this.reqHeader });
  }
  editTrabajo(trabajo: any, id: string) {
    return this.http.post( global.API_URL + '/api/trabajos/update/' + id, trabajo, { headers: this.reqHeader });
  }
  editEvento(trabajo: any, id: string) {
    return this.http.post( global.API_URL + '/api/trabajos/update_evento/' + id, trabajo, { headers: this.reqHeader });
  }
  deleteTrabajo(id: string) {
    return this.http.delete(global.API_URL + '/api/trabajos/delete/' + id, { headers: this.reqHeader });
  }
  newTrabajoNota(nota: any, trabajo:any) {
    return this.http.post( global.API_URL + '/api/trabajos/create_nota/' + trabajo, nota, { headers: this.reqHeader });
  }
  newTrabajoSesion(sesion: any, trabajo:any) {
    return this.http.post( global.API_URL + '/api/trabajos/create_sesion/' + trabajo, sesion, { headers: this.reqHeader });
  }
  deleteTrabajoNota(id: string) {
    return this.http.delete(global.API_URL + '/api/trabajos/delete_nota/' + id, { headers: this.reqHeader });
  }
  signConsentimiento(consentimiento: any, trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/firma_consentimiento/' + trabajo, consentimiento, { headers: this.reqHeader });
  }
  saveDniRemote(dnis: any, cliente: any) {
    return this.http.post( global.API_URL + '/api/trabajos/save_dni_remote/' + cliente, dnis, { headers: this.reqHeader });
  }
  solicitarConsentimiento(trabajo:any){
    return this.http.get(global.API_URL + '/api/trabajos/solicitar_consentimiento/' + trabajo, { headers: this.reqHeader });
  }
  IdiomaConsentimientoTelematico(trabajoId: number, idioma: string, dnirequerido: any) {
    const formData = new FormData();
    formData.append('idioma', idioma);
    formData.append('dnirequerido', dnirequerido);
    const url = `${global.API_URL}/api/trabajos/guardar_idioma_cons_telematico/${trabajoId}/${idioma}/${dnirequerido}`;
    return this.http.post(url, formData, { headers: this.reqHeader });
  }
  buscar_trabajos(fecha_inicial, fecha_final, descripcion, estudio_id) {
    const body = {
        fecha_inicial: fecha_inicial,
        fecha_final: fecha_final,
        descripcion: descripcion,
        estudio_id: estudio_id
    };
    return this.http.post(global.API_URL + '/api/trabajos/buscar_trabajos', body, { headers: this.reqHeader });
  }
  getConteoTipos(estudioId: any) {
    return this.http.get(global.API_URL + '/api/trabajos/contartipos/' + estudioId, { headers: this.reqHeader });
  }
  getConteoTiposGrafica(estudioId: any) {
    return this.http.get(global.API_URL + '/api/trabajos/contartiposgrafica/' + estudioId, { headers: this.reqHeader });
  }
  obtenerTicketMedio(estudioId: number, tipoProyecto: number, faseCita: number | null) {
    let fase = (faseCita === null) ? 'null' : faseCita;
    return this.http.get(`${global.API_URL}/api/trabajos/ticketMedio/${estudioId}/${tipoProyecto}/${fase}`, { headers: this.reqHeader });
  }
  obtenerBeneficiosTotalesPorTipo(estudioId: any) {
    return this.http.get(global.API_URL + '/api/trabajos/beneficiosTotalesPorTipo/' + estudioId, { headers: this.reqHeader });
  }
  obtenerTopArtistasIngresos(estudioId: any): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/obtenerTopArtistasIngresos/' + estudioId, { headers: this.reqHeader });
  }
  obtenerTopArtistasIngresosNew(estudioId: any, fechaInicio: string, fechaFin: string): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/obtenerTopArtistasIngresosNew/${estudioId}/${fechaInicio}/${fechaFin}`, { headers: this.reqHeader });
  }
  obtenerTopArtistasTrabajos(estudioId: number): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/topArtistasTrabajos/' + estudioId, { headers: this.reqHeader });
  }
  obtenerTopArtistasTrabajosNew(estudioId: number, fechaInicio: string, fechaFin: string): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/topArtistasTrabajosNew/${estudioId}/${fechaInicio}/${fechaFin}`, { headers: this.reqHeader });
  }
  obtenerBeneficiosPorArtista(estudioId: number): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/' + estudioId + '/beneficios_por_artista', { headers: this.reqHeader });
  }
  obtenerBeneficiosPorArtistaNew(estudioId: number, fechaInicio: string, fechaFin: string): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/${estudioId}/beneficios_por_artista_new/${fechaInicio}/${fechaFin}`, { headers: this.reqHeader });
  }

  obtenerArtistaConMasCitasCanceladas(estudioId: number): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/artistaConMasCitasCanceladas/' + estudioId, { headers: this.reqHeader });
  }
  obtenerArtistaConMasCitasCanceladasNew(estudioId: number, fechaInicio: string, fechaFin: string): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/artistaConMasCitasCanceladasNew/${estudioId}/${fechaInicio}/${fechaFin}`, { headers: this.reqHeader });
  }
  obtenerTiempoMedioEspera(estudioId: number): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/tiempoMedioEspera/' + estudioId, { headers: this.reqHeader });
  }
  obtenerArtistaMasSolicitado(estudioId: number): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/artistaMasSolicitado/' + estudioId, { headers: this.reqHeader });
  }
  obtenerArtistaMasSolicitadoNew(estudioId: number, fechaInicio: string, fechaFin: string): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/artistaMasSolicitadoNew/${estudioId}/${fechaInicio}/${fechaFin}`, { headers: this.reqHeader });
  }
  obtenerGananciasArtistasSemanasRecientes(estudioId: number): Observable<any> {
    return this.http.get(global.API_URL + '/api/trabajos/gananciasArtistasSemanasRecientes/' + estudioId, { headers: this.reqHeader });
  }
  asignacionWalkinArtistasNew(estudioId: number, fechaInicio: string, fechaFin: string): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/asignacionWalkinArtistasNew/${estudioId}/${fechaInicio}/${fechaFin}`, { headers: this.reqHeader });
  }

  getEventosEstudio(estudio: any) {
    return this.http.get(global.API_URL + '/api/trabajos/eventos/estudio/' + estudio, { headers: this.reqHeader });
  }
  anadirTintasAlTrabajo(trabajoId: number, tintasSeleccionadas: any[]) {
    console.log("Enviando al backend:", { trabajoId, tintasSeleccionadas });
    return this.http.post(global.API_URL + '/api/trabajos/anadir_tintas', 
      { trabajoId, tintasSeleccionadas }, 
      { headers: this.reqHeader });
  }
  getTintasTrabajo(trabajoId: any) {
    return this.http.get(global.API_URL + '/api/trabajos/tintas/' + trabajoId, { headers: this.reqHeader });
  }
  
  anadirAgujasAlTrabajo(trabajoId: number, agujasSeleccionadas: any[]) {
    console.log("Enviando al backend:", { trabajoId, agujasSeleccionadas });
    return this.http.post(global.API_URL + '/api/trabajos/anadir_agujas', 
      { trabajoId, agujasSeleccionadas }, 
      { headers: this.reqHeader });
  }
  getAgujasTrabajo(trabajoId: any) {
    return this.http.get(global.API_URL + '/api/trabajos/agujas/' + trabajoId, { headers: this.reqHeader });
  }

  getTrabajosPorFecha(fecha: string, estudioId: number) {
    return this.http.get(global.API_URL + '/api/trabajos/fecha/'+fecha+'/estudio/'+estudioId, { headers: this.reqHeader });
  }
  getSenalesPorFecha(fecha: string, estudio_id: number) {
    return this.http.get(`${global.API_URL}/api/trabajos/senales/${estudio_id}/${fecha}`, { headers: this.reqHeader });
  }
  filtrarTrabajosRangoFechas(estudioId: number, start: string, end: string) {
    return this.http.get(
      global.API_URL + `/api/trabajos/rango/${estudioId}/${start}/${end}`,
      { headers: this.reqHeader }
    );
  }
  
  guardarCitasMultiples(citas: any) {
    console.log("Attempting to send citas:", citas);
    return this.http.post(global.API_URL + '/api/trabajos/guardar_citas_multiples', citas, { headers: this.reqHeader });
  }
  obtenerTopClientes(estudioId: number): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/topclientes/${estudioId}`, { headers: this.reqHeader });
  }
  topClientesPorCitas(estudioId: number): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/topClientesPorCitas/${estudioId}`, { headers: this.reqHeader });
  }
  enviarCuidadosPost(trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/enviar_cuidados_post/' + trabajo, {}, { headers: this.reqHeader });
  }
  actualizarTrabajoDesdeCalendario(data: any): Observable<any> {
    return this.http.post(global.API_URL + '/api/trabajos/update_express_calendarNEW', data, { headers: this.reqHeader });
  }
  crearEventoCalendario(data: any): Observable<any> {
    return this.http.post(global.API_URL + '/api/trabajos/crearEventoCalendario', data, { headers: this.reqHeader });
  }

  verificarSolapamiento(payload: any) {
    return this.http.post(global.API_URL + '/api/trabajos/verificarSolapamiento', payload, { headers: this.reqHeader });
  }
  trabajoNuevoCalendario(data: any): Observable<any> {
    return this.http.post(global.API_URL + '/api/trabajos/trabajoNuevoCalendario', data, { headers: this.reqHeader });
  }  
  contarTrabajosCliente(estudio_id: number, cliente_id: number): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/contarTrabajosCliente/${estudio_id}/${cliente_id}`, { headers: this.reqHeader });
  }
  

  
  

  






  

  
  
  //regenerarConsentimiento(consentimiento: any, trabajo: any) {
    //return this.http.post( global.API_URL + '/api/trabajos/regenerar_consentimiento/' + trabajo, consentimiento, { headers: this.reqHeader });
  //}
  regenerarConsentimiento(consentimiento: any, trabajo: any, enviar: boolean = true) {
    const body = { ...consentimiento, enviar };
    return this.http.post(global.API_URL + '/api/trabajos/regenerar_consentimiento/' + trabajo, body, { headers: this.reqHeader });
  }
  
  signConsentimientoPiercing(consentimiento: any, trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/firma_consentimiento/' + trabajo, consentimiento, { headers: this.reqHeader });
  }
  signConsentimientoLaser(consentimiento: any, trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/firma_consentimiento/' + trabajo, consentimiento, { headers: this.reqHeader });
  }
  signConsentimientoMicroblading(consentimiento: any, trabajo: any) {
    return this.http.post( global.API_URL + '/api/trabajos/firma_consentimiento/' + trabajo, consentimiento, { headers: this.reqHeader });
  }
  confirmarNotificacion(notificacion){
    return this.http.get(global.API_URL + '/api/estudios/confirmar_notificacion/' + notificacion, { headers: this.reqHeader });
  }
  confirmarNotificacionCita(cita){
    return this.http.get(global.API_URL + '/api/trabajos/confirmar/' + cita, { headers: this.reqHeader });
  }
  confirmarNotificacionNota(nota){
    return this.http.get(global.API_URL + '/api/trabajos/confirmar_nota/' + nota, { headers: this.reqHeader });
  }
  finalizarNotificacionCita(cita, id){
    return this.http.post(global.API_URL + '/api/trabajos/finalizar/' + id, cita, { headers: this.reqHeader });
  }
  cierreDiario(estudio){
    return this.http.get(global.API_URL + '/api/trabajos/cierre_diario/' + estudio, { headers: this.reqHeader });
  }

  newFotoTrabajo(documento:any, trabajo:any){
    return this.http.post( global.API_URL + '/api/trabajos/subir_foto/' + trabajo, documento, { headers: this.reqHeader });
  }
  borrarFotoTrabajo(id: string) {
    return this.http.delete(global.API_URL + '/api/trabajos/borrar_foto/' + id, { headers: this.reqHeader });
  }
  finalizarTodosTrabajos(estudioId: number, trabajoIds: number[]): Observable<any> {
    return this.http.post(`${global.API_URL}/api/trabajos/finalizar_todos/${estudioId}`, { trabajoIds }, { headers: this.reqHeader });
  }
  terminarCitasAntiguas(estudioId: number, trabajoIds: number[]): Observable<any> {
    const body = { trabajoIds: trabajoIds };
    return this.http.post(`${global.API_URL}/api/trabajos/terminar_citas_antiguas/${estudioId}`, body, { headers: this.reqHeader });
  }
  getConsentimientos(id: number): Observable<any> {
    return this.http.get(`${global.API_URL}/api/trabajos/consentimientos/${id}`, { headers: this.reqHeader });
  }
  //Eliminar un consentimiento
  eliminarConsentimiento(trabajoId: number): Observable<any> {
    return this.http.delete(`${global.API_URL}/api/trabajos/consentimiento/${trabajoId}`, { headers: this.reqHeader });
  }

  //subida múltiple de fotos para el trabajo
  subirFotosTrabajo(formData: FormData): Observable<any> {
    return this.http.post(global.API_URL + '/api/trabajos/subir_fotos', formData, { headers: this.reqHeader });
  }  
  
  // recibos
  guardarRecibo(reciboData: any): Observable<any> {
    return this.http.post<any>(`${global.API_URL}/api/recibos`, reciboData, { headers: this.reqHeader });
  }
  buscarReciboPorServicio(trabajoId: number): Observable<any> {
    return this.http.get<any>(`${global.API_URL}/api/recibos/buscar-por-servicio/${trabajoId}`, { headers: this.reqHeader });
  }
  getNextNumeroRecibo(estudio_id: number): Observable<any> {
    return this.http.get<any>(`${global.API_URL}/api/recibos/siguiente-numero/${estudio_id}`, { headers: this.reqHeader });
  }


  // emails

  getEmailsEstudio(estudio:any) {
    return this.http.get(global.API_URL + '/api/emails/estudio/'+estudio, { headers: this.reqHeader });
  }
  getEmails() {
    return this.http.get(global.API_URL + '/api/emails', { headers: this.reqHeader });
  }
  deleteEmail(id: string) {
    return this.http.delete(global.API_URL + '/api/emails/delete/' + id, { headers: this.reqHeader });
  }
  getEmail(id: string) {
    return this.http.get(global.API_URL + '/api/emails/view/' + id, { headers: this.reqHeader });
  }
  newEmail(cliente: any) {
    return this.http.post( global.API_URL + '/api/emails/create/', cliente, { headers: this.reqHeader });
  }
  editEmail(cliente: any, id: string) {
    return this.http.post( global.API_URL + '/api/emails/update/' + id, cliente, { headers: this.reqHeader });
  }
}